import { Component } from '@angular/core';
import { createEmptyStateSnapshot } from '@angular/router/src/router_state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor() {
    this.removeLoading();
  }

  removeLoading() {

    const loading = document.getElementById('loading');
    if (loading) {
      loading.style.display = 'none';
    }
  }

  ngOnInit() {

  }
}
