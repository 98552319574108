import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EmailService } from 'src/app/core/services/email.service';

@Component({
  selector: 'app-initial-modal',
  templateUrl: './initial-modal.component.html',
  styleUrls: ['./initial-modal.component.scss']
})
export class InitialModalComponent implements OnInit {
  modalRef: BsModalRef;
  modalOpened: boolean = false;
  public loadingEmail = false;
  public newsletterForm: FormGroup;

  @ViewChild('template') template: TemplateRef<any>;

  constructor(private modalService: BsModalService, private emailService: EmailService) { }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    const scroll = window.scrollY;
    if (scroll > 10 && !this.modalOpened) {
      this.modalRef = this.modalService.show(this.template, Object.assign({}, { class: 'gray-modal-lg' }));
      this.modalOpened = true;
    }
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.newsletterForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required]),
    });
  }

  sendEmail() {
    this.loadingEmail = true;
    this.emailService.sendNewsletterEmail(this.newsletterForm.value).subscribe((res: any) => {
      console.log('Success', res);
      this.loadingEmail = false;
    });
  }

}
