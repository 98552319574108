import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ScrollToService } from 'ng2-scroll-to-el';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {

  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  constructor(private router: Router, private scrollToService: ScrollToService) {}


  @HostListener('window:click') hideMenu() {
    this.detectRouterChange();
  }

  detectRouterChange() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.navbarOpen = false
      }
    });
  }

  navigateToDevsSection() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      take(1)
    ).subscribe((event: NavigationEnd) => {
      if (event.url === '/') {
        const section = document.getElementById('devs');
        if (section) {
          const rect = section.getBoundingClientRect();
          const offset = rect.top + window.scrollY - 830;
          window.scrollTo({ top: offset, behavior: 'smooth' });
        }
      }
    });
    this.router.navigate(['/']);
  }

  navigateToAboutUsSection() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      take(1)
    ).subscribe((event: NavigationEnd) => {
      if (event.url === '/') {
        const section = document.getElementById('about-us');
        if (section) {
          const rect = section.getBoundingClientRect();
          const offset = rect.top + window.scrollY - 830;
          window.scrollTo({ top: offset, behavior: 'smooth' });
        }
      }
    });
    this.router.navigate(['/']);
  }



  ngOnInit(): void {
  }
}
