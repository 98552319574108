import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';

import { ScrollToModule } from "ng2-scroll-to-el";
import { RouterModule } from '@angular/router';
import { InitialModalComponent } from './components/initial-modal/initial-modal.component';
import { WspFollowComponent } from './components/wsp-follow/wsp-follow.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmailService } from '../core/services/email.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    InitialModalComponent,
    WspFollowComponent
  ],
  imports: [
    CommonModule,
    ScrollToModule.forRoot(),
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    InitialModalComponent,
    WspFollowComponent,
  ],
    providers: [
    EmailService
  ]
})
export class SharedModule { }
